import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import type { SelectProps } from "clutch/src/Select/Select.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import { EXCLUDED_COMPETITIVE_MAPS, TDM_MAPS } from "@/game-val/constants.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ButtonsWrapper = styled("div")`
  .select {
    display: none;
  }
  ${mobile} {
    .buttons {
      display: none;
    }
    .select {
      display: block;
    }
  }
`;

interface SelectMapProps extends Omit<SelectProps<string>, "options"> {
  mode?: string;
  asButtons?: boolean;
}

export default function SelectMap({
  selected,
  asButtons,
  onChange,
  mode,
  ...restProps
}: SelectMapProps) {
  const {
    val: { meta: { maps: { list = [] } = {} } = {} },
  } = useSnapshot(readState);
  const { t } = useTranslation();

  const filteredMaps = useMemo(() => {
    if (!list) return [];
    if (mode === "hurm")
      return list.filter(({ key }) => TDM_MAPS.includes(key));
    return list.filter(
      ({ key }) =>
        !EXCLUDED_COMPETITIVE_MAPS.includes(key) && !TDM_MAPS.includes(key),
    );
  }, [mode, list]);

  const options = useMemo(
    () => [
      {
        text: ["val:allMaps", "All Maps"] as const,
        value: "all",
      },
      ...filteredMaps.map(({ name, key }) => {
        return {
          text: [`val:maps.${key}`, name] as const,
          value: key,
        };
      }),
    ],
    [filteredMaps],
  );
  if (asButtons) {
    return (
      <ButtonsWrapper>
        <ButtonGroup className="buttons">
          {options.map(({ text, value }) => (
            <Button
              className={
                (selected || options[0].value) === value ? "active" : ""
              }
              onClick={() => onChange(value)}
              key={value}
            >
              {t(...text)}
            </Button>
          ))}
        </ButtonGroup>
        <Select
          containerClassName="select"
          selected={selected || options[0].value}
          options={options}
          onChange={onChange}
          {...restProps}
        />
      </ButtonsWrapper>
    );
  }
  return (
    <Select
      selected={selected || options[0].value}
      options={options}
      onChange={onChange}
      {...restProps}
    />
  );
}
